/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import {
  Button, Container, Grid, Typography,
} from '@mui/material';
import { navigate } from 'gatsby-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo';
import vector from '../img/Vector.svg';
import playStore from '../img/play-store.png';
import appStore from '../img/app-store.svg';
import Layout, { LayoutContext } from '../components/Layout';
import video from '../img/index.mp4';
import placeholder from '../img/mobile-placeholder.png';

import '@fontsource/mulish';
import '@fontsource/viga';
import '@fontsource/poppins';
import '@fontsource/georama';
import '@fontsource/nunito';
import '@fontsource/source-sans-pro';
import '@fontsource/space-grotesk';
import '@fontsource/montserrat';
import '@fontsource/open-sans';
import '@fontsource/hanken-grotesk';

// eslint-disable-next-line
export const IndexPageTemplate = ({ image, title, subheading, mainpitch, mainpitch2 }) => {
  const fullWidthImage = getImage(image) || image;
  const fullWidthMainpitchImage = getImage(mainpitch.image) || mainpitch.image;
  const fullWidthMainpitch2Image = getImage(mainpitch2.image) || mainpitch2.image;

  const { mobileMode } = React.useContext(LayoutContext);

  const [formState, setFormState] = useState({ isValidated: false });
  const [waitlistFormState, setWaitlistFormState] = useState({ isValidated: false });

  function encode(data) {
    return Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  }

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleWaitlistChange = (e) => {
    setWaitlistFormState({ ...waitlistFormState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  const handleWaitlistSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...waitlistFormState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <div>
      <Container maxWidth="lg" sx={mobileMode ? { py: 4 } : { pt: 3, pb: 14 }}>
        <Grid container direction="column" rowGap={3}>
          <Grid container alignItems="center" direction={mobileMode ? 'column' : 'row'} rowGap={3}>
            <Grid item xs={12} md={7}>
              <Grid container direction="column" rowGap={2}>
                <Typography
                  variant="h1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Viga',
                        fontSize: '36px',
                        fontWeight: '400',
                        lineHeight: '45px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                      }
                      : {
                        fontFamily: 'Viga',
                        fontSize: '67px',
                        fontWeight: '400',
                        lineHeight: '90px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                      }
                  }
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Mulish',
                        fontSize: '22px',
                        fontWeight: '400',
                        lineHeight: '35px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: '#8C959F',
                      }
                      : {
                        fontFamily: 'Mulish',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#8C959F',
                      }
                  }
                >
                  {subheading}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <GatsbyImage
                image={fullWidthImage}
                alt="Example of golf group in the application over green background"
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={mobileMode ? 'center' : 'flex-start'}
            columnGap={3}
            rowGap={3}
            direction={mobileMode ? 'column' : 'row'}
          >
            <Link to="/how-to-create-golf-foursome">
              <Button
                variant="contained"
                size="large"
                style={{
                  fontFamily: 'Mulish',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '0em',
                  backgroundColor: '#5EBC67',
                  textTransform: 'none',
                }}
                sx={{ py: 2, px: 4 }}
              >
                Learn How It Works
              </Button>
            </Link>
            <Link to="/play">
              <Button
                variant="outlined"
                size="large"
                style={{
                  fontFamily: 'Mulish',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '0em',
                  borderColor: '#00000036',
                  color: '#0000007A',
                  textTransform: 'none',
                }}
                sx={{ py: 2, px: 4 }}
              >
                Play Now
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="none"
        sx={mobileMode ? { py: 4 } : { py: 10, backgroundColor: '#F9FBFC' }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction={mobileMode ? 'column-reverse' : 'row'}
            rowGap={2}
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="column"
                rowGap={mobileMode ? 2 : 5}
                alignItems={mobileMode ? 'center' : 'flex-start'}
              >
                <Typography
                  variant="h2"
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '38px',
                    fontWeight: '600',
                    lineHeight: '64px',
                    letterSpacing: '0em',
                    color: '#283646',
                  }}
                >
                  {mainpitch.title}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '22px',
                    fontWeight: '400',
                    lineHeight: '48px',
                    letterSpacing: '0px',
                    textAlign: 'left',
                    color: '#000F2080',
                  }}
                >
                  {mainpitch.description}
                </Typography>
                <Link to="/who-its-for">
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '17px',
                      fontWeight: '500',
                      lineHeight: '26px',
                      letterSpacing: '0em',
                      textTransform: 'none',
                      color: 'white',
                      maxWidth: '225px',
                      backgroundColor: '#5EBC67',
                    }}
                    sx={{ py: 2, px: 3 }}
                  >
                    Explore More
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container justifyContent="center">
                <Grid item>
                  <video
                    style={{ height: '500px' }}
                    controls
                    poster={mobileMode ? placeholder : null}
                  >
                    <source src={video} type="video/mp4" />
                  </video>
                </Grid>
              </Grid>
              {/* <GatsbyImage image={fullWidthMainpitchImage} alt="Golf squad teeing off" /> */}
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container
        maxWidth="none"
        sx={
          mobileMode
            ? { backgroundColor: '#5AB862', py: 4 }
            : { backgroundColor: '#5AB862', pt: 10 }
        }
        id="waitlist"
      >
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            direction={mobileMode ? 'column' : 'row'}
            rowGap={3}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6}>
              <GatsbyImage image={fullWidthMainpitch2Image} alt="Two people playing group golf" />
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid
                container
                direction="column"
                rowGap={mobileMode ? 3 : 5}
                alignItems={mobileMode ? 'center' : 'flex-start'}
              >
                <Typography
                  variant="h2"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Poppins',
                        fontSize: '29px',
                        fontWeight: '600',
                        lineHeight: '39px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: 'white',
                      }
                      : {
                        fontFamily: 'Poppins',
                        fontSize: '41px',
                        fontWeight: '600',
                        lineHeight: '64px',
                        letterSpacing: '0em',
                        color: 'white',
                      }
                  }
                >
                  {mainpitch2.title}
                </Typography>
                <Typography
                  variant="body1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Mulish',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        color: 'white',
                        textAlign: 'center',
                      }
                      : {
                        fontFamily: 'Mulish',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        color: 'white',
                      }
                  }
                >
                  {mainpitch2.description}
                </Typography>
                <Grid
                  container
                  sx={{ flexWrap: 'nowrap' }}
                  alignContent="center"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <a href="https://apps.apple.com/us/app/squaddie/id6451010321">
                    <img
                      src={appStore}
                      alt="Download Squaddie on the Apple App Store"
                      style={{ height: '68px' }}
                    />
                  </a>

                  <a href="https://play.google.com/store/apps/details?id=io.ionic.Squaddie">
                    <img
                      src={playStore}
                      alt="Download Squaddie on the Android Play Store"
                      style={{ width: '250px' }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <img
        src={vector}
        style={{ width: '100vw', marginTop: '-2px' }}
        alt="A website element that makes the page look like a golf fairway"
      />
      <Container maxWidth="lg" sx={mobileMode ? { py: 8 } : { py: 15 }}>
        <Grid container alignItems="center" direction="column" rowGap={8}>
          <Grid container alignItems="center" direction="column" rowGap={1}>
            <Typography
              variant="h3"
              style={{
                fontFamily: 'Poppins',
                fontSize: '38px',
                fontWeight: '600',
                lineHeight: '64px',
                letterSpacing: '0em',
                color: '#283646',
                textAlign: mobileMode ? 'center' : 'left',
              }}
            >
              Feel free to Contact Us
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: 'Poppins',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '27px',
                letterSpacing: '0em',
                color: '#797979',
                textAlign: mobileMode ? 'center' : 'left',
              }}
            >
              Enter the following details to reach us
            </Typography>
          </Grid>
          <form
            name="contact"
            method="post"
            action="/contact-us/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>
            <Grid container direction="column" rowGap={mobileMode ? 2 : 6}>
              <Grid item>
                <Grid container rowGap={2}>
                  <Grid item xs="12" md="4" sx={!mobileMode && { pr: 6 }}>
                    <div className="field">
                      <label
                        className="label"
                        htmlFor="first_name"
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: '#797979',
                        }}
                      >
                        First Name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name="first_name"
                          onChange={handleChange}
                          id="first_name"
                          required
                          style={{
                            borderColor: '#D3D3D3',
                            borderRadius: '20px',
                            fontFamily: 'Poppins',
                            fontSize: '19px',
                            fontWeight: '400',
                            lineHeight: '29px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            height: '80px',
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs="12" md="4" sx={!mobileMode && { pr: 6 }}>
                    <div className="field">
                      <label
                        className="label"
                        htmlFor="last_name"
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: '#797979',
                        }}
                      >
                        Last Name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name="last_name"
                          onChange={handleChange}
                          id="last_name"
                          required
                          style={{
                            borderColor: '#D3D3D3',
                            borderRadius: '20px',
                            fontFamily: 'Poppins',
                            fontSize: '19px',
                            fontWeight: '400',
                            lineHeight: '29px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            height: '80px',
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs="12" md="4">
                    <div className="field">
                      <label
                        className="label"
                        htmlFor="email"
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: '#797979',
                        }}
                      >
                        Email
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          id="email"
                          required
                          style={{
                            borderColor: '#D3D3D3',
                            borderRadius: '20px',
                            fontFamily: 'Poppins',
                            fontSize: '19px',
                            fontWeight: '400',
                            lineHeight: '29px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            height: '80px',
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <div className="field">
                <label
                  className="label"
                  htmlFor="message"
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '22px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: '#797979',
                  }}
                >
                  Message
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name="message"
                    onChange={handleChange}
                    id="message"
                    required
                    style={{
                      borderColor: '#D3D3D3',
                      borderRadius: '20px',
                      fontFamily: 'Poppins',
                      fontSize: '19px',
                      fontWeight: '400',
                      lineHeight: '29px',
                      minHeight: '50vh',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}
                  />
                </div>
              </div>
              <Grid item alignSelf="center">
                <div className="field">
                  <button
                    className="button is-link"
                    type="submit"
                    style={{
                      padding: '2rem',
                      backgroundColor: '#5EBC67',
                      fontFamily: 'Nunito',
                      fontSize: '18px',
                      fontWeight: '700',
                      lineHeight: '25px',
                      letterSpacing: '0em',
                      borderRadius: '41px',
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Container>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  mainpitch2: PropTypes.object,
};

function IndexPage({ data, location }) {
  const { frontmatter } = data.markdownRemark;
  React.useEffect(() => {
    const scrollToElement = () => {
      const target = document.querySelector(location.state.scrollTo);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      } else {
        setTimeout(scrollToElement, 100);
      }
    };

    if (location.state && location.state.scrollTo) {
      scrollToElement();
    }
  }, [location]);
  return (
    <Layout>
      <GatsbySeo
        title="Squaddie - The Caddie for your Golf Squad"
        description="Looking for the easiest way to schedule golf outings with your golf group? Our app takes the hassle out of planning, so you can spend more time on the course with your golf squad. Try it now!"
        canonical="https://www.squaddie.co/"
      />
      <JsonLd
        json={{
          context: 'https://schema.org',
          url: 'https://www.squaddie.co/',
          images: (frontmatter.image, frontmatter.mainpitch.image, frontmatter.mainpitch2.image),
          datePublished: '2023-03-10T08:00:00+08:00',
          // dateModified="2023-03-10T09:00:00+08:00",
          authorName: 'Squaddie',
          description:
            'Looking for the easiest way to schedule golf outings with your golf group? Our app takes the hassle out of planning, so you can spend more time on the course with your golf squad. Try it now!',
          '@type': 'SoftwareApplication',
          name: 'Squaddie',
          applicationCategory: 'UtilitiesApplication',
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '5.0',
            ratingCount: '1',
          },
          offers: {
            '@type': 'Offer',
            price: '0',
          },
        }}
      />

      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        mainpitch2={frontmatter.mainpitch2}
      />
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        mainpitch2 {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
